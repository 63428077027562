.Tab {
  width: 80px;
  background-color: transparent;
  border: none;
  color: black !important;
}

.Tab:hover {
  background-color: #dadada !important;
}

.Tab.active {
  background-color: #dadada !important;
}

/* .Tab img {
  width: 22px;
} */
