.LocationPicker .map-search-container {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 90px;
}

.LocationPicker .map-search-container .spinner-border {
  position: absolute;
  right: 8px;
}

.LocationPicker .map-search-input {
  width: 100%;
  padding-right: 30px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
}

.LocationPicker .map-search-input.is-invalid,
.LocationPicker .map-search-input.is-invalid:focus {
  background-image: none;
  box-shadow: red 0 0 0 0.1rem;
}

.LocationPicker .map-search-input:focus {
  box-shadow: black 0 0 0 0.1rem;
}

.LocationPicker .map-location-button-container {
  margin-top: 10px;
  margin-right: 10px;
}

.LocationPicker .map-location-button {
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  border-radius: 2px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
}
