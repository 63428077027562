@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* TODO: Don't override bootstrap classes.*/

* {
  font-family: 'Poppins', sans-serif;
}

html {
  font-size: 0.8rem;
  -webkit-font-smoothing: antialiased;
}

.bg-light {
  background-color: #f2f2f2 !important;
}

.absolute-fill {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.limit-width {
  max-width: 790px;
  margin: auto;
}

.pre-wrap {
  white-space: pre-wrap;
}

.btn-group.expand {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.btn.rounded-circle {
  flex-shrink: 0;
  width: 64px;
  height: 64px;
}

.btn-small {
  width: 50px !important;
  height: 50px !important;
}

.form-control {
  border: none;
  background-color: #f2f2f2;
}

.form-group {
  border-bottom: 1px solid #979797;
  padding-bottom: 1.3em;
}
