.CircleCheckbox {
  color: initial;
}

.CircleCheckbox:hover,
.CircleCheckbox:focus {
  color: initial;
  text-decoration: none;
}

.CircleCheckbox .outer-circle {
  padding: 3px;
  border: 2px solid transparent;
}

.CircleCheckbox.big .inner-circle {
  width: 80px;
  height: 80px;
  padding: 3px;
}

.CircleCheckbox.small .inner-circle {
  width: 64px;
  height: 64px;
  padding: 3px;
}

.CircleCheckbox.big.checked .outer-circle {
  border-color: black;
}

.CircleCheckbox.small.checked .outer-circle {
  background-color: black;
  color: white;
}
