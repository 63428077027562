.TaskStatusIndicator p {
  display: flex;
  align-items: center;
  margin: 0;
}

.TaskStatusIndicator p::after {
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: red;
  margin-left: 10px;
}

/* Open */
.TaskStatusIndicator > p.open_offer {
  color: black;
}

.TaskStatusIndicator > p.open_offer::after {
  background-color: black;
}

/* Open */
.TaskStatusIndicator > p.open_request {
  color: black;
}

.TaskStatusIndicator > p.open_request::after {
  background-color: black;
}

/* Pending */
.TaskStatusIndicator > p.awaiting_transporter {
  color: orange;
}

.TaskStatusIndicator > p.awaiting_transporter::after {
  background-color: orange;
}

/* Pending */
.TaskStatusIndicator > p.awaiting_approval {
  color: orange;
}

.TaskStatusIndicator > p.awaiting_approval::after {
  background-color: orange;
}

/* Approved */
.TaskStatusIndicator > p.approved {
  color: green;
}

.TaskStatusIndicator > p.approved::after {
  background-color: green;
}

/* Approved */
.TaskStatusIndicator > p.collection_complete {
  color: green;
}

.TaskStatusIndicator > p.collection_complete::after {
  background-color: green;
}

/* Closed */
.TaskStatusIndicator > p.delivery_complete {
  color: silver;
}

.TaskStatusIndicator > p.delivery_complete::after {
  background-color: silver;
}

/* invalid */
.TaskStatusIndicator > p.invalid {
  color: red;
}

.TaskStatusIndicator > p.invalid::after {
  background-color: red;
}
