.TaskSummary {
  background-color: #f2f2f2;
  border-radius: 15px;
}

.TaskSummaryContainerButton {
  background: none;
  border: none;
  outline: none;
  text-align: left;
}

.TaskSummary .description p {
  margin-bottom: 0;
}
