@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/* TODO: Don't override bootstrap classes.*/

* {
  font-family: 'Poppins', sans-serif;
}

html {
  font-size: 0.8rem;
  -webkit-font-smoothing: antialiased;
}

.bg-light {
  background-color: #f2f2f2 !important;
}

.absolute-fill {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.limit-width {
  max-width: 790px;
  margin: auto;
}

.pre-wrap {
  white-space: pre-wrap;
}

.btn-group.expand {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.btn.rounded-circle {
  flex-shrink: 0;
  width: 64px;
  height: 64px;
}

.btn-small {
  width: 50px !important;
  height: 50px !important;
}

.form-control {
  border: none;
  background-color: #f2f2f2;
}

.form-group {
  border-bottom: 1px solid #979797;
  padding-bottom: 1.3em;
}

.circle-drop-shadow {
  color: white;
  width: 64px;
  height: 64px;
  min-width: 64px;
  min-height: 64px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.5);
}

.BrandHeader {
  background-color: black;
  color: white;
}

.CheckBox {
  width: 42px;
  height: 42px;
  border-radius: 10px;
  background-color: lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LocationPicker .map-search-container {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 90px;
}

.LocationPicker .map-search-container .spinner-border {
  position: absolute;
  right: 8px;
}

.LocationPicker .map-search-input {
  width: 100%;
  padding-right: 30px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
}

.LocationPicker .map-search-input.is-invalid,
.LocationPicker .map-search-input.is-invalid:focus {
  background-image: none;
  box-shadow: red 0 0 0 0.1rem;
}

.LocationPicker .map-search-input:focus {
  box-shadow: black 0 0 0 0.1rem;
}

.LocationPicker .map-location-button-container {
  margin-top: 10px;
  margin-right: 10px;
}

.LocationPicker .map-location-button {
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  border-radius: 2px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
}

.Tab {
  width: 80px;
  background-color: transparent;
  border: none;
  color: black !important;
}

.Tab:hover {
  background-color: #dadada !important;
}

.Tab.active {
  background-color: #dadada !important;
}

/* .Tab img {
  width: 22px;
} */

.UserTabBar {
  background-color: white;
}

.AppContainer > .content {
  padding-bottom: 76px;
}

.ListButton {
  font-size: 1.5rem;
  color: black;
}

.TaskTitleImageContainer {
  display: block;
  font-size: 1rem;
}

.TaskStatusIndicator p {
  display: flex;
  align-items: center;
  margin: 0;
}

.TaskStatusIndicator p::after {
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: red;
  margin-left: 10px;
}

/* Open */
.TaskStatusIndicator > p.open_offer {
  color: black;
}

.TaskStatusIndicator > p.open_offer::after {
  background-color: black;
}

/* Open */
.TaskStatusIndicator > p.open_request {
  color: black;
}

.TaskStatusIndicator > p.open_request::after {
  background-color: black;
}

/* Pending */
.TaskStatusIndicator > p.awaiting_transporter {
  color: orange;
}

.TaskStatusIndicator > p.awaiting_transporter::after {
  background-color: orange;
}

/* Pending */
.TaskStatusIndicator > p.awaiting_approval {
  color: orange;
}

.TaskStatusIndicator > p.awaiting_approval::after {
  background-color: orange;
}

/* Approved */
.TaskStatusIndicator > p.approved {
  color: green;
}

.TaskStatusIndicator > p.approved::after {
  background-color: green;
}

/* Approved */
.TaskStatusIndicator > p.collection_complete {
  color: green;
}

.TaskStatusIndicator > p.collection_complete::after {
  background-color: green;
}

/* Closed */
.TaskStatusIndicator > p.delivery_complete {
  color: silver;
}

.TaskStatusIndicator > p.delivery_complete::after {
  background-color: silver;
}

/* invalid */
.TaskStatusIndicator > p.invalid {
  color: red;
}

.TaskStatusIndicator > p.invalid::after {
  background-color: red;
}


.SimpleGuide {
  background: #e0eaf2;
}

.SimpleGuide p {
  padding: 0;
  margin: 0;
}

.TaskSummary {
  background-color: #f2f2f2;
  border-radius: 15px;
}

.TaskSummaryContainerButton {
  background: none;
  border: none;
  outline: none;
  text-align: left;
}

.TaskSummary .description p {
  margin-bottom: 0;
}

.CircleCheckbox {
  color: #000;
  color: initial;
}

.CircleCheckbox:hover,
.CircleCheckbox:focus {
  color: #000;
  color: initial;
  text-decoration: none;
}

.CircleCheckbox .outer-circle {
  padding: 3px;
  border: 2px solid transparent;
}

.CircleCheckbox.big .inner-circle {
  width: 80px;
  height: 80px;
  padding: 3px;
}

.CircleCheckbox.small .inner-circle {
  width: 64px;
  height: 64px;
  padding: 3px;
}

.CircleCheckbox.big.checked .outer-circle {
  border-color: black;
}

.CircleCheckbox.small.checked .outer-circle {
  background-color: black;
  color: white;
}

.SideButtonsInput button.disabled {
  opacity: 1;
  background-color: #7b8083;
  border-color: #7b8083;
}

.SideButtonsInput input {
  -moz-appearance: textfield;
}

.SideButtonsInput input::-webkit-outer-spin-button,
.SideButtonsInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

