.SideButtonsInput button.disabled {
  opacity: 1;
  background-color: #7b8083;
  border-color: #7b8083;
}

.SideButtonsInput input {
  -moz-appearance: textfield;
}

.SideButtonsInput input::-webkit-outer-spin-button,
.SideButtonsInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
