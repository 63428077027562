.circle-drop-shadow {
  color: white;
  width: 64px;
  height: 64px;
  min-width: 64px;
  min-height: 64px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.5);
}
